
.study-page,
.study-body,
.practice-body,
.practice-page,
.ipa,
.explanation,
.conjugation{
  color: #062E5F;  
}

.study-page {
  width: 100%;
  margin: 0 auto;
  padding: 5px;
  box-sizing: border-box;  
} 

.summary-body{
  margin: 30px auto 70px auto;  
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.study-body {     
  padding: 10px;
  margin: 0px auto;     
  box-sizing: border-box;
}

.sentence-with-controls-1, .sentence-with-controls-2  {  
  margin: 0px 0 ;
  text-align: left;
}
.sentence-with-controls-1 span  {   
  margin: 0px auto;     
  font-size: clamp(18px, 7vw, 28px) !important;
  display: inline-block;
}
.sentence-with-controls-2 span {  
  line-height: 2;
  font-size: clamp(18px, 7vw, 28px) !important;
}

.speaker-button,
.slowdown-button,
.translation-button , 
.transcription-button   {
  width: 50px;
  height: 50px;
  padding: 0px; 
  margin-right: 30px; 
  border: none;
  background-color: transparent; 
  cursor: pointer;
}

.speaker-button:hover,
.slowdown-button:hover,
.translation-button:hover,
.transcription-button:hover {
  transform: translateY(-2px);
}

.playback-rate-control{
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto; 
}

input[type=range] {
  -webkit-appearance: none;
  width: 70%;
  height: 8px;
  background: #e3e3e3;
  border-radius: 10px;
  outline: none;
  padding: 0;
  margin: 0;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px; /* Size of the circle */
  height: 20px;
  border-radius: 50%;
  background-color: #66D5C1; 
  cursor: pointer;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

button.decrease-rate, button.increase-rate {
  background: none;
  border: none;
  color: #66D5C1;
  font-size: clamp(18px, 7vw, 35px);
  cursor: pointer;
  padding: 0px;
}

.translation-area {
  font-size: clamp(15px, 4vw, 25px) !important;
  margin: 0 0 10px 0;
  width: 90%;
  padding: 10px;
  color: #E07F9C; 
  border-radius: 10px;
  background-color: transparent;
  box-shadow: 0px 3px 3px 3px #E8E8E8;
  transition: box-shadow 0.3s, transform 0.3s;
}

.word {
  cursor: pointer;
  margin-right: 5px;
}

.selected {
  background-color: #FFEDCB;
} 

.microphone {
  text-align: center;
  position: relative; 
  margin: 10px auto;
  height: 190px;
  max-width: 500px;
  border-radius: 10px;
  background-color: #F5F5F5;
  cursor: pointer;
}
.microphone-button {
  cursor: pointer;
  background: none;
  border: none;
  margin: auto;
  position: absolute;
  top: calc((100% - 184px) / 2);
  left: calc((100% - 184px) / 2);
}
.recording-animation {  
  background: none;
  cursor: pointer;
  border: none;
  position: absolute;
  top: calc((100% - 134px) / 2);     /* adjust position due to animation*/
  left: calc((100% - 144px) / 2);
  animation: pulseAnimation 2s infinite; 
}

.microphone-button-small {
  cursor: pointer;
  background: #F0F0F0;
  border-radius: 50%; 
  border: none;
  padding: 15px 25px 15px 25px; 
  margin-top: -5px;
  margin-left: calc((100% - 80px) / 2);
  margin-right: calc((100% - 80px) / 2);
  margin-bottom: 10px;  
  scale: 0.7;  
}
.recording-animation-small{
  background: none; 
  cursor: pointer;
  border: none;
  margin-top: 0px; 
  animation: pulseAnimation 2s infinite;
  margin-left: calc((100% - 60px) / 2);
  margin-right: calc((100% - 60px) / 2);
  margin-bottom: 15px;
  scale: 0.7;  
}

.vocab-item{
  margin: 10px auto;
  width: 300px;
  border-radius: 10px;
  background-color: rgba(255,244,223, 0.8); 
  color: #062E5F;
  padding: 10px;
}
.vocab-item span {    
  box-sizing: border-box; 
  font-size: clamp(16px, 3vw, 18px) !important;
}

.french-word {
  margin-right: 5px;
  color: #D5668A;
  font-weight: 500;
}
.ipa {
  margin-right: 10px;
}

.audio-button{
  background: #F0F0F0;
  width: 30px;
  height: 30px;
  padding: 0px; 
  margin-right: 10px; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.audio-button:hover, .favorite-button:hover{
  transform: translateY(-1px);
}

.favorite-button{
  background: #F0F0F0;
  width: 30px;
  height: 30px;
  padding: 0px; 
  margin-right: 10px; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.explanation {
  padding-top: 8px;  
}

.conjugation{
  color: #062E5F;
}

@keyframes pulseAnimation { 
  50% {
      transform: translate(-10%, -10%) scale(1.1);
  }
}

/* .instruction-summary-bubble {
  width: 180px;
  top: 80px; 
  margin-left: 0;
  top: 80px; 
  z-index: 1000; 
  padding: 7px 20px;
  background-color: #333;
  color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeInOut 2s forwards; 
}
@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
} */

.show-audio{
  border: none;
  border-radius: 5px;
  padding: 7px 10px;
  cursor: pointer;
  background: rgba(0, 123, 255, 0.75);
  color: white;
  margin: 5px 5px 0px auto;
  display: flex;
  justify-content: end;
}


.toggle-button {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 5px 10px;
  align-items: center; /* Vertically center the text and triangle */    
  width: 100%;   
  color: #062e5f;   
  background-color:rgba(6,49,95,0.3); 
  border-color: #062e5f;
  box-shadow: 0 1px 0 rgba(6,49,95,0.8); 
  border-radius: 5px;
  cursor: pointer; 
}
.toggle-button:hover{
  transform: translateY(-2px);
}

.summary-sentences{  
  background-color: #f5f5f5; 
  border-radius: 10px;
  padding: 10px;
  width: 100%; 
  box-sizing: border-box;
  text-align: left;
  cursor: pointer;
}
.summary-sentences p{  
  color: #062e5f;
  margin: 0;
  font-size: clamp(17px, 3.5vw, 20px) !important;
}
.summary-active{  
  background-color: #FFF4DF;
}

.speaker-button-summary,
.slowdown-button-summary   {
  width: 30px;
  height: 30px;
  padding: 0px; 
  margin-right: 35px; 
  border: none;
  background-color: transparent; 
  cursor: pointer;
}

.practice-button, .practice-speak-button, .practice-write-button{
  padding: 12px 20px;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: clamp(16px, 3vw, 18px) !important;
  cursor: pointer; 
  transition: all 0.3s; 
  display: flex;
  align-items: center;
  background-color: #062E5F;
}

.speaker-button-summary:hover,
.slowdown-button-summary:hover,
.summary-sentences:hover,
.practice-button:hover, 
.practice-speak-button:hover, 
.practice-write-button:hover {
  transform: translateY(-2px);
}

.buttons-in-summary {
  display: flex;
  justify-content: space-between; 
  width: 100%; 
  margin-top: 10px;;
}

.point-bubble {
  background-color: #66d5c1;
  color: white;
  font-size: clamp(15px, 3.2vw, 20px) !important;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 50px;
  opacity: 1;
  animation: fadeOut 1s ease-out forwards;
  z-index: 1000;
}
.x-button-vocab-box { 
  font-weight: 700;
  background: none;
  border: none;
  cursor: pointer;
  font-size: clamp(20px, 4vw, 25px) !important;    
  color: #71d8c9;
  /* background: rgba(255,237,203, 0.8); */
  background: #F0F0F0;
  border-radius: 50px;
  padding: 5px 10px;
}  
@media (min-width: 600px) {  
  .study-body,
  .summary-body {       
    padding-left: 30px;
    padding-right: 30px;
  }
  .summary-sentences{ 
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 800px) {
  .study-body,
  .summary-body {       
    padding-left: 70px;
    padding-right: 70px;
  }
}
@media (min-width: 1100px) {  
  .study-page {
    max-width: 80%;
  }  
  .bottom-menu {
    width: calc(80% - 20px);
    margin: 0 auto; 
  }
  .study-body,
  .summary-body {       
    max-width: 1000px;
  }   
}

