.popup-burgermenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #71d8c9;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  overflow-y: auto;  
}
.burger-menu-icon {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 20px;
  }

  /* Video Popup Overlay Styling */
.video-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Dark background overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000; /* Above the burger menu */
}
/* Responsive full-screen video */
.responsive-video {
  width: 90%;
  max-width: 800px;
  height: auto;
  max-height: 80vh;
  object-fit: contain;
}
/* Close button for video popup */
.video-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  z-index: 2100;
}

.answer-form {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #c4c4c4;
  border-radius: 20px;
  padding: 5px;
  margin: auto;
  box-shadow: 0 0 10px #ccc inset;
}
.answer-input {
  width: calc(100% - 10px); 
  margin-left: 5px;   
  font-size: clamp(15px, 3.5vw, 20px) !important;
  border: none;    
  resize: none; 
  overflow-y: auto;
}
.answer-input:focus {
  outline: none;
}
.practice-write-submit-button {
  background: transparent;
  cursor: pointer;
  border: none;
  border-radius: 40%;
  padding: 0px;
}
.practice-write-submit-button:focus {
  outline: none;
}

.popup-link{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;  
  background-color: #71d8c9;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;  
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 30px;  
  box-sizing: border-box; /* Ensure padding is included in the element’s total height */  
}
.popup-link li, .popup-link li:hover {
  list-style-type: disc; /* Ensures bullet points are shown */
  margin: 0 0 5px 30px;  
} 
.popup-link img {
  padding-left: 10px;
}
.popup-link p, .popup-link span {
  color: #062E5F;
  font-size: clamp(14px, 3.5vw, 18px) !important;
 }

.popup-container-layout{
  padding: 7px;
  color: #062E5F;
  background:   rgba(255,193,69, 0.3);  
}
.popup-container {
position: absolute;
background: white;
border-radius: 5px;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
max-width: 300px;
max-height: 300px;
z-index: 1000;
overflow-wrap: break-word;
overflow: auto;
font-size: clamp(10px, 3vw, 16px) !important;
}
.popup-button-group {
display: flex;
justify-content: space-between;
}
.popup-close-button {
  border: none;
  background: none;
  color: #062E5F;
  position: absolute;
  top: 5px;
  right: 5px;  
  cursor: pointer;
}
.popup-button {  
border: none;
border-radius: 5px;
padding: 7px 10px;
cursor: pointer;
margin: 5px 2px 0 2px;
}
.popup-button#prevButton {
background: #ffffff;
color: #062E5F;
border: 1px solid #062E5F;
}
.popup-button#nextButton{
background: #062E5F;
color: white;
}

.x-button {
  position: absolute;  
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: clamp(18px, 4vw, 32px) !important;  
  }  

  .search-bar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 70px;
    margin-bottom: 2px;
    width: 85%;
    max-width: 700px;
    position: relative;
  }
  
  .search-bar input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: clamp(17px, 3.5vw, 20px) !important;
  }
  
  .search-icon-button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 10px; /* Position icon inside input */
    top: 50%;
    transform: translateY(-50%);
  }

  .menu-buttons {
    margin-top: 30px;
    display: flex;
    flex-direction: column;    
    gap: 30px;
    width: 80%;
    padding-left: 10px;
  }
  
  .menu-button {
    background: none;
    border: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
    font-size: clamp(20px, 3.5vw, 25px) !important;
  }
  
  .menu-button:hover {
    text-decoration: underline;
  }

  .search-results {
    position: relative;    
    border: none;
    outline: none;
    border-radius: 5px;
    width: 85%;
    max-height: 200px;
    max-width: 700px;
    overflow-y: auto;
  }
  
  .search-result-button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: white;
    text-align: left;
    cursor: pointer;
    font-size: clamp(17px, 3.5vw, 20px) !important;
  }
  
  .search-result-button:hover {
    background-color: #f0f0f0;
  }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7); 
  z-index: 998; /* Below the highlighted element */
  pointer-events: none; /* Allows interaction with highlighted elements */
}

.highlighted-element {
  position: relative;
  z-index: 999; /* Above the overlay */
  box-shadow: 0 0 10px 5px rgba(255, 193, 69, 0.8); /* Glow effect */
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #66D5C1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  width: auto; 
  max-width: 90%;
  max-height: 80%; 
  overflow-y: auto; 
  overflow-x: hidden;  
 }

 .bottom-menu {
  position: fixed;
  bottom: 0;
  width: calc(100% - 20px);
  margin: auto;
  background-color: #71d8c9; 
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 7px 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 500; 
}

.menu-icon, .help-icon, .flag-icon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.menu-icon:hover img, .help-icon:hover img, .flag-icon:hover img {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}

.answer-form-flag {
  display: flex;
  width: 80%;
  min-height: 200px;
  margin-top: 60px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}

.practice-message{  
  max-width: 350px; 
  word-wrap: break-word; 
  text-align: center;
}

.french-keyboard {  
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 5px;
}

.toggle-keyboard-button {
  padding: 5px 5px 10px 5px;
  font-weight: 700;
  color: #062e5f;
  background-color: #e3e3e3;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: clamp(13px, 3.2vw, 18px) !important;
}

.keyboard-key {
  background-color: #F0F0F0;
  font-weight: 700;
  color: #062E5F;
  border: none;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: clamp(13px, 3.2vw, 18px) !important;
}

.keyboard-key:hover,
.toggle-keyboard-button:hover {
  transform: translateY(-2px);
}