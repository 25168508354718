.button-groups-container {
    width: 100%;
    margin: 0 auto;
    padding: 5px;
    box-sizing: border-box;
  }
  
  .button-group {
    width: 70%;
    margin: auto;
    border: 2.5px solid #66D5C1;
    border-radius: 8px;
    margin-bottom: 15px; /* Space between each group */
    overflow: hidden; /* Ensures that the border-radius is applied to children elements */
  }
  .button-item button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: white;
    text-align: left;
    font-size: clamp(12px, 3vw, 16px);
    cursor: pointer;
  }
  
  .button-item hr {
    border: none;
    height: 1px;
    background-color: #ccc;
    margin: 0 auto; /* Resetting default margins for consistent spacing */
    width: calc(100% - 20px);
  }
  
  @media (min-width: 550px) {
    .button-groups-container {
      max-width: 80%;
      font-size: clamp(14px, 1vw, 16px);
    }
  }
  @media (min-width: 800px) {
    .button-groups-container {
      max-width: 60%;
      font-size: clamp(14px, 1vw, 16px);
    }
  }
  @media (min-width: 1224px) {
    .button-groups-container {
      max-width: 35%;
      font-size: clamp(16px, 0.7vw, 18px);
    }
  }