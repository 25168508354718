.start-container {
  text-align: center;    
  vertical-align: middle;
  justify-content: center;
  height: 100vh;    
  margin: 0 auto;
}

.logo img {
  max-width: 300px;
  height: auto;
  position: absolute;
  top: 22%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.language-selector {
  top: 40%;
  left: 50%;
}
.level-selector {
  top: 47%;
  left: 50%;
}
.language-selector select,
.level-selector select {  
  cursor: pointer;
  font-size: clamp(14px, 3vw, 15px) !important;
  width: 100%; 
  max-width: 220px; 
  padding: 8px;
  border: 1px solid #ccc; 
  border-radius: 5px;
  background-color: white;
  color: #333;
  box-sizing: border-box;
  appearance: none; 
  -webkit-appearance: none; /* Ensures compatibility with Safari */
  -moz-appearance: none; /* Ensures compatibility with Firefox */
}
.language-selector,
.level-selector {
  display: inline-block;
  width: 100%; 
  max-width: 220px;
  position: absolute;
  transform: translate(-50%, -50%);
}
.language-selector::after,
.level-selector::after {
  content: '▼'; 
  transform: translateY(-50%);
  font-size: 12px;
  color: #333;
  position: absolute;
  top: 50%;
  right: 10px;  
  pointer-events: none;
}

.instruction-link {
  background: none;
  border: none;
  font-weight: bold;
  font-size: clamp(15px, 3.3vw, 17px) !important;  
  cursor: pointer;
  font-style: italic;
  text-decoration: underline;
  color: #062E5F;  
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%); 
}
.responsive-video {
  width: 100%;
  height: auto;
  /* aspect-ratio: 16/9; */
}

.start-button,
.signup-button {
  font-size: clamp(18px, 3.5vw, 22px) !important;
  padding: 10px;
  margin: 10px 0; 
  width: 30%; 
  max-width: 120px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  transition: border-radius 0.2s; /* Smooth transition for border-radius */
  background-color: #66D5C1; 
  color: white;  
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%); 
 }

.start-button:hover,
.signup-button:hover {
border-radius: 15px; 
}


@media (min-width: 600px) {
  .logo img {        
      max-width: 300px;
  }
  .language-selector select, 
  .level-selector select {
    width: 260px; 
  }
}
@media (min-width: 800px) {  
  /* .start-button {
    font-size: clamp(14px, 2vw, 16px); 
      width: 20%;  
  } */
}
@media (min-width: 1100px) {
  
}