  .filter-button {
    margin: 30px auto 0px auto;  
    display: flex;
    align-items: center;
    color: #062e5f;
    padding: 10px;
    border-radius: 10px;
    background-color: #F5F5F5;
    cursor: pointer;
    border: none;
    width: 100%;    
    font-size: clamp(18px, 4vw, 22px) !important;
    font-weight: 700;
  }

.filter-dropdown {
    margin: auto;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 1000;
    width: calc(100%-20px);
}
.filter-dropdown hr {
    background-color: #F0F0F0;
    border: none;
    height: 1px;
}
.filter-dropdown label {
    font-size: clamp(13px, 3.2vw, 19px) !important;
    color: #062e5f;
}

.filter-dropdown h4 {
    margin: 0 0 10px 0;
    text-align: left;
}

/* Data preferences with Toggle Switch */
.filter-data{
    margin-bottom: 30px;
}
.filter-date, .filter-skills {
    margin-bottom: 30px;
}
.filter-date, .filter-skills, .filter-levels {
    margin-top: 20px;
}
.filter-levels {
    margin-bottom: 15px;
}

.filter-data small {
    display: block;
    margin-top: 5px;
    color: gray;
    font-size: clamp(12px, 2.8vw, 17px) !important;
}
.toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin-left: 10px;
}
.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.toggle-switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 20px;
    transition: 0.4s;
}
.toggle-switch input:checked + .slider {
    background-color: #66D5C1;
}
.toggle-switch .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
}
.toggle-switch input:checked + .slider:before {
    transform: translateX(20px);
}

/* Date */
.date-row {
    display: flex;
    justify-content: space-between;
}
.date-column {
    flex: 1;
}
.date-column label {
    display: block;
    margin-bottom: 5px;
}
.date-column input {
    width: calc(100% - 5px);    
    padding: 5px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    box-sizing: border-box;
    color: gray;
    font-size: clamp(12px, 2.8vw, 17px) !important;
}

/* Levels */
.level-button {
    background-color: transparent;
    border: 1px solid #c4c4c4;
    border-radius: 50px;
    padding: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    min-width: 40px;
    cursor: pointer;
    font-size: clamp(12px, 2.8vw, 17px) !important;
    color: #062e5f;
}
.level-button:hover,
.level-button.selected{
    background-color: #66D5C1;
    color: white;
}

/* Skills */
.filter-skills label {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 5px;
}
.filter-skills input {
    cursor: pointer;
}

/* Buttons */
.filter-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
.filter-reset-button,
.filter-search-button {
    margin-top: 15px;
    padding: 8px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    min-width: 120px;
    font-size: clamp(12px, 3.2vw, 18px) !important;
}
.filter-reset-button {
    background-color: #f0f0f0;
    color: #062e5f;
    font-weight: 600;
}
.filter-search-button {
    background-color: #062e5f;
    color: white;
}
.filter-reset-button:hover,
.filter-search-button:hover {
    transform: translateY(-2px);
}

.reports {
    margin: 30px auto;
    box-sizing: border-box;
  }

  .table-content th {
    font-size: clamp(12px, 3.2vw, 18px) !important;
  }
  .table-content td {
    font-size: clamp(12px, 3.2vw, 18px) !important;
  }

  .score-button{
    background-color: #062e5f;
    color: white;
    border: none;
    padding: 5px;
    font-size: clamp(12px, 2.8vw, 17px) !important;
    border-radius: 20%;
    cursor: pointer;
    min-width: 35px;
  }
  .score-button:hover{
    transform: translateY(-2px);
  }

  @media (min-width: 600px) {
    .reports {
        max-width: 80%;
      }  
  }