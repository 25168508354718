.theme-selection-container {
  width: 100%;
  margin: 0 auto;
  padding: 5px;
  box-sizing: border-box;
}

.themes-grid, .themes-grid-novice {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 30px 0;
  max-width: 90%;
  margin: 0 auto 20px auto;
  box-sizing: border-box;
}
.themes-grid-novice {
  padding-top: 50px;
}

.theme-card, .theme-card-novice {
  border-radius: 10px;
  overflow: hidden;  
  transition: box-shadow 0.3s, transform 0.3s;
  text-align: center;
  padding-bottom: 20px;
  margin: auto;
  width: 100%;
  max-width: 100%;  
}
.theme-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.theme-card-novice {
  padding-top: 20px;
  border: 3px solid;
  height: 35px;
  display: flex;
  align-items: center;
}

.theme-card:hover, .theme-card-novice:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-4px);
  cursor: pointer;
}

.theme-image {
  width: 100%;
  height: auto;
}

.theme-name {
  position: relative;  
  background-color: inherit;
  color: #062E5F;
  font-weight: 550;
  width: 100%;
  border: none;
  cursor: pointer;
  font-size: clamp(12px, 3.5vw, 18px) !important;
  transition: background-color 0.3s, transform 0.3s;
  word-wrap: break-word;
}
  
@media (min-width: 500px) {
  .theme-card {
    width: 180px;
  }
  .theme-card-novice {
    padding-top: 20px;
    width: 200px;
  }
}
@media (min-width: 700px) {
  .themes-grid {
    grid-template-columns: repeat(3, 1fr);
  }  
}
@media (min-width: 850px) {
  .themes-grid {
    grid-template-columns: repeat(4, 1fr);
  }
  .themes-grid-novice {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1100px) {
  .theme-selection-container {
      max-width: 70%;
    }
}